import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/mswsn/projects/vcs-childhood-elearning-q2-2020/src/components/layout.tsx";
import { ChapterLink } from "../components/chapter-link";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Var en viktig vuxen`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1082px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.66666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIFA//EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAVbILqEgQ//EABkQAAMBAQEAAAAAAAAAAAAAAAECEgMAIv/aAAgBAQABBQJhnXlVV1VTdEVxzev/xAAYEQADAQEAAAAAAAAAAAAAAAAAARESMf/aAAgBAwEBPwHTsH0//8QAFhEBAQEAAAAAAAAAAAAAAAAAEQEQ/9oACAECAQE/AWOf/8QAHxAAAgIABwEAAAAAAAAAAAAAAAECERIhIjFRYZFx/9oACAEBAAY/AlTlX0ylP0WKXrOuSN7Ieqj/xAAcEAEAAwACAwAAAAAAAAAAAAABABFBIWExUaH/2gAIAQEAAT8hGn0Uqzg4gQBa7ppPc6R8pY+Yyii+AZ//2gAMAwEAAgADAAAAEMAv/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQARIcH/2gAIAQMBAT8QEFdeQan/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QwAOwl//EABsQAQACAwEBAAAAAAAAAAAAAAERIQBBUbEx/9oACAEBAAE/EDAnTPHkVeKzTQERmJ1rGOEMm+PmBqLTJIum6xVfU3CteYivLkIhs9z/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Barn på en karusell",
          "title": "Barn på en karusell",
          "src": "/static/0a6772716034d5ae5d658eb2dad2230b/d6334/carousel.jpg",
          "srcSet": ["/static/0a6772716034d5ae5d658eb2dad2230b/f93b5/carousel.jpg 300w", "/static/0a6772716034d5ae5d658eb2dad2230b/b4294/carousel.jpg 600w", "/static/0a6772716034d5ae5d658eb2dad2230b/d6334/carousel.jpg 1082w"],
          "sizes": "(max-width: 1082px) 100vw, 1082px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Volvo Car Sverige och svensk Volvohandel är sedan 2003 partners till Childhood. Men även du som medarbetare har möjlighet att engagera dig lite extra. I detta utbildningsmaterial kan du lära dig mer om Childhood, vilka frågor de arbetar med och hur du som vuxen kan förbättra tillvaron för barn i din omgivning.`}</p>
    <ChapterLink url="kapitel-1" title="Childhood och vårt samarbete" type="chapter" mdxType="ChapterLink" />
    <ChapterLink url="kapitel-2" title="Barnens vardag" type="chapter" mdxType="ChapterLink" />
    <ChapterLink url="kapitel-3" title="Hur blir jag en viktig vuxen?" type="chapter" mdxType="ChapterLink" />
    <ChapterLink url="kapitel-4" title="Kunskapstest" type="quiz" mdxType="ChapterLink" />
    <ChapterLink url="kapitel-5" title="Avslutning" type="chapter" mdxType="ChapterLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      